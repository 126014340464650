.main {
    margin: 1%;
}

.input {
    width: 95%;
    margin-inline: 1%;
}

.menu {
    width: 100%;
    height: 100%;
    margin-inline: auto;
}