.input {
    width: 50%;
    margin-inline: 1%;
}

.main {
    flex-direction: row;
}

.header {
    height: 30%;
}