.header {
    padding:10px 40px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
  }
  
  .myButton{
    font-size: 20px
}

  .teamIcon {
    font-size: 23px;
    margin-right: 12px;
  }